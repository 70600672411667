import WixExperiments from '@wix/wix-experiments';
import { EditorScriptFlowAPI } from '../constants/types';

let conductedExperiments: WixExperiments;

export const initExperiments = async (flowApi: EditorScriptFlowAPI) => {
  conductedExperiments = await flowApi.getExperiments();
};

export const isADIHorizontalEnabled = () =>
  conductedExperiments.enabled('specs.membersArea.ADIHorizontalLayout');
