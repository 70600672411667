import {
  PROFILE_LAYOUT_PARAM_KEY,
  PROFILE_WIDGET_HORIZONTAL_LAYOUT_STYLE_PARAM,
} from '../constants/apps';
import { EditorSDK } from '../constants/types';

async function getCardCompRef(editorSDK: EditorSDK, appDefId: string) {
  const { applicationId } = await editorSDK.tpa.app.getDataByAppDefId(
    '',
    appDefId,
  );
  const [tpaCompRef] = await editorSDK.tpa.app.getAllCompsByApplicationId(
    '',
    applicationId,
  );
  return editorSDK.components.getById('', { id: tpaCompRef.id });
}

// In order to make sure the new layouts are coming with correct default app setting, we have to set it from editor script
// As this happens only on install, we always expect only one profile card widget to be installed
export async function setNewLayoutStyleForProfileWidget(
  editorSDK: EditorSDK,
  appDefId: string,
) {
  const compRef = await getCardCompRef(editorSDK, appDefId);
  return editorSDK.document.tpa.setStyleParams('', {
    compRef,
    styleParams: [PROFILE_WIDGET_HORIZONTAL_LAYOUT_STYLE_PARAM],
  });
}

export async function getLayoutType(editorSDK: EditorSDK, appDefId: string) {
  const compRef = await getCardCompRef(editorSDK, appDefId);
  const params = await editorSDK.document.tpa.getStyleParams('', { compRef });
  return params.numbers[PROFILE_LAYOUT_PARAM_KEY];
}
